<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <input
              type="date"
              class="form-control input-lg"
              v-model="searchDate"
            />
            <Button
              icon="pi pi-search"
              class="p-button-success p-pr-5 p-pl-5"
              @click="searchSchedule()"
            />
          </div>
          <Button
            icon="pi pi-print"
            class="p-ml-2 p-pr-5 p-pl-5 p-button-primary"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <div class="col-md-12 text-center p-p-0">
          <h5 class="checkout-heading">
            Week No : {{ weekNo }}
            <i class="pi pi-ellipsis-h"></i>
            Week Date Range
            <span style="background-color: #dc4535">
              {{ formatDate(weekStartingDate) }}
            </span>
            -
            <span style="background-color: #dc4535">
              {{ formatDate(weekEndingDate) }}
            </span>
            <i class="pi pi-ellipsis-h"></i>
            No of Associates : {{ filterAssociates.length }}
            <i class="pi pi-ellipsis-h"></i>
            Hours Approved : {{ formatAmount(noOfTotalApprovedHrs) }} Hrs
          </h5>
        </div>
        <DataTable
          :value="filterAssociates"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column field="employeeId" header="Associate ID">
            <template #body="slotProps">
              <span
                :class="{
                  highlighted: slotProps.data.aprrovedHrs <= 0,
                }"
              >
                {{ slotProps.data.employeeId }}
              </span>
            </template>
          </Column>
          <Column header="Associate Full Name">
            <template #body="slotProps">
              <b
                >{{ slotProps.data.employeeFirstName.toUpperCase() }}
                {{ slotProps.data.employeeLastName.toUpperCase() }}
              </b>
            </template>
          </Column>
          <Column
            field="employeeJobClass"
            header="Associate Job Class"
          ></Column>
          <Column header="Total Approved Hours">
            <template #body="slotProps">
              {{ formatAmount(slotProps.data.aprrovedHrs) }} Hrs
            </template>
          </Column>
          <Column header="Action" style="width:30rem;">
            <template #body="slotProps">
              <span class="p-buttonset">
                <Button
                  icon="pi pi-calendar"
                  label="Associate Hours"
                  class="p-button-success p-p-1"
                  :disabled="slotProps.data.aprrovedHrs > 0"
                  @click="
                    openSchedule(
                      slotProps.data.employeeId,
                      slotProps.data.employeeFirstName +
                        ' ' +
                        slotProps.data.employeeLastName,
                      slotProps.data.employeeJobClass
                    )
                  "
                />
                <Button
                  icon="pi pi-calendar"
                  label="Preview Hours"
                  class="p-button-primary p-p-1"
                  :disabled="slotProps.data.aprrovedHrs <= 0"
                  @click="openApprovedHrs(slotProps.data.employeeId,slotProps.data.employeeFirstName + ' ' +slotProps.data.employeeLastName)"
                />
                <Button
                  icon="pi pi-pencil"
                  label="Adjustments"
                  class="p-button-warning p-p-1"
                  :disabled="slotProps.data.aprrovedHrs <= 0"
                  @click="openApprovedAdjustmentHrs(slotProps.data.employeeId,slotProps.data.employeeFirstName + ' ' +slotProps.data.employeeLastName,slotProps.data.employeeJobClass)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <div class="p-mt-4" v-if="showScheduleListStatus == true">
          <div class="checkout-heading">
            <div class="p-d-flex p-jc-between">
              <h6 class="p-pt-2">
                <i class="pi pi-calendar"></i> Associate Working Schedule For
              </h6>
              <span class="highlight-associate">
                {{ activeAssociateName }} ( {{ activeAssociateID }} )
              </span>
            </div>
          </div>
          <div class="col-md-12 p-p-0 p-m-0">
            <table class="table table-bordered schedule-style">
              <tr>
                <th style="width:10rem">Date</th>
                <th style="width:10rem">Pay code</th>
                <th class="ap-sch-hrs" style="width:20rem">Schedule hrs</th>
                <th style="width:50rem">Notes</th>
              </tr>
              <tr
                v-for="associate in checkScheduleContainsLogedHrs"
                :key="associate"
              >
                <td>
                  <span class="shift-date">{{
                    formatDate(associate.shiftDate)
                  }}</span>
                </td>
                <td>
                  <template v-if="associate.paycode != ''">
                    {{ associate.paycode.toUpperCase() }}
                  </template>
                  <!-- <template v-else-if="associate.paycode == ''">
                    <span @click="redirectToschedule()" class="highlighted"
                      >SET PAY CODE</span
                    >
                  </template> -->
                </td>
                <td class="ap-sch-hrs">
                  <template v-if="associate.paycode != ''">
                    <span>
                      <i class="pi pi-clock"></i>
                      {{ formatTime(associate.timeIn) }} -
                      {{ formatTime(associate.timeOut) }}
                      <span class="p-ml-5">
                        {{
                          getTimeDiff(
                            associate.timeOut,
                            associate.timeIn,
                            associate.breakTimings
                          )
                        }}
                      </span>
                    </span>
                  </template>
                </td>
                <td style="width: 15vw; font-size: 14px">
                  <small v-if="associate.paycode != ''">{{
                    associate.notes
                  }}</small>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-12 p-p-2 checkout-heading">
            <div class="p-d-flex p-jc-between">
              <div class="p-mt-2">
                <h6>
                  <i class="pi pi-calendar"></i> Associate Logged Hours For
                  <span class="highlight-associate">
                    {{ activeAssociateName }} ( {{ activeAssociateID }} )
                  </span>
                </h6>
              </div>
              <div>
                <span class="p-buttonset">
                  <Button
                    label="Add Logged Hrs"
                    class="p-button-info p-p-1"
                    @click="openTimingDailog()"
                    icon="pi pi-plus-circle"
                  />
                  <Button
                    label="Approve Hours"
                    class="p-button-success p-p-1 p-ml-2"
                    @click="approveAssociateHours()"
                    :disabled="
                      dateWiseLoggedHrs('ALL') != dateWiseAssignedHrs('ALL') ||
                        nonePayCodeLen > 0 ||
                        dateWiseAssignedHrs('ALL') <= 0
                    "
                    icon="pi pi-check-circle"
                  />
                  <Button
                    label="Logged Hours"
                    class="p-button-warning p-p-1 p-ml-2"
                    @click="openAttendance()"
                    icon="pi pi-key"
                  />
                  <Button
                    icon="pi pi-info-circle"
                    class="p-button-danger p-p-1 p-ml-2"
                    label="Help"
                    @click="infoDialog = true"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 p-p-0 p-m-0">
            <div
              class="p-d-flex p-jc-between p-p-1"
              style="background-color:#dc4535; color:#fff;"
            >
              <h6 class="p-text-center p-m-0 ">
                Actual Logged {{ formatAmount(dateWiseLoggedHrs("ALL")) }} Hrs
              </h6>
              <h6 class="p-text-center p-m-0 ">
                Week Adjusted {{ formatAmount(dateWiseAssignedHrs("ALL")) }} Hrs
              </h6>
            </div>
            <DataTable
              :value="sortedLoggedHrs"
              class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            >
              <template #empty>
                <div class="p-text-center p-p-3">No records found</div>
              </template>
              <Column header="DEL">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-times"
                    v-if="slotProps.data.payCode == 'None'"
                    class="p-button-danger p-p-1 p-mr-2 p-m-0"
                    @click="deleteHours(slotProps.data)"
                  />
                </template>
              </Column>
              <Column header="Date">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.loggedHrsDate) }}
                </template>
              </Column>
              <Column header="Pay code">
                <template #body="slotProps">
                  <Dropdown
                    style="width:15rem"
                    :options="paycodeList"
                    optionLabel="name"
                    optionValue="name"
                    v-model="slotProps.data.payCode"
                  />
                </template>
              </Column>
              <Column header="Logged Hrs">
                <template #body="slotProps">
                  <span class="p-mx-2">
                    <input
                      type="time"
                      :disabled="slotProps.data.payCode == 'Overtime'"
                      v-model="slotProps.data.loggedInHr"
                    />
                  </span>
                  -
                  <span class="p-mx-2">
                    <input
                      type="time"
                      :disabled="slotProps.data.payCode == 'Overtime'"
                      v-model="slotProps.data.loggedOutHr"
                    />
                  </span>
                </template>
              </Column>
              <Column header="Break Timing">
                <template #body="slotProps">
                  {{ slotProps.data.breakTiming }}
                </template>
              </Column>
              <Column header="Total Hours">
                <template #body="slotProps">
                  {{ slotProps.data.totalHrs }}
                </template>
              </Column>
              <Column header="Approve Hours">
                <template #body="slotProps">
                  <div class="p-inputgroup">
                    <Button
                      icon="pi pi-plus-circle"
                      @click="slotProps.data.approveHrs++"
                      class="p-button-success"
                    />
                    <InputNumber
                      v-model="slotProps.data.approveHrs"
                      mode="decimal"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                    />
                    <Button
                      icon="pi pi-minus-circle"
                      @click="slotProps.data.approveHrs--"
                      class="p-button-danger"
                    />
                  </div>
                </template>
              </Column>
              <Column header="Notes">
                <template #body="slotProps">
                  <InputText
                    v-model="slotProps.data.notes"
                    placeholder="Write any adjustment notes.."
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="loginHoursDialog"
    :style="{ width: '95vw' }"
    :maximizable="true"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        {{ loginDetails.title }}
      </h5>
    </template>
    <div class="row">
      <div class="col-md-12">
        <p>
          <b
            >Associate ID : {{ activeAssociateName }} ({{
              activeAssociateID
            }})</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="p-grid">
          <div class="p-col-8">
            <Calendar
              v-model="loginDetails.loginDate"
              :inline="true"
              :manualInput="false"
              dateFormat="yy-mm-dd"
            />
          </div>
          <div class="p-col-2" >
            <div v-if="loginDetails.attendenceID == ''">
              <h5 class="date-time-clock-hrs-heading">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                Login
              </h5>
              <div class="clock-hours-timming">
                <h5 @click="setLoginTime('08:00')" class="date-time-clock-hrs">
                  08:00 AM
                </h5>
                <h5 @click="setLoginTime('09:00')" class="date-time-clock-hrs">
                  09:00 AM
                </h5>
                <h5 @click="setLoginTime('10:00')" class="date-time-clock-hrs">
                  10:00 AM
                </h5>
                <h5 @click="setLoginTime('11:00')" class="date-time-clock-hrs">
                  11:00 AM
                </h5>
                <h5 @click="setLoginTime('12:00')" class="date-time-clock-hrs">
                  12:00 PM
                </h5>
                <h5 @click="setLoginTime('13:00')" class="date-time-clock-hrs">
                  01:00 PM
                </h5>
                <h5 @click="setLoginTime('14:00')" class="date-time-clock-hrs">
                  02:00 PM
                </h5>
                <h5 @click="setLoginTime('15:00')" class="date-time-clock-hrs">
                  03:00 PM
                </h5>
                <h5 @click="setLoginTime('16:00')" class="date-time-clock-hrs">
                  04:00 PM
                </h5>
                <h5 @click="setLoginTime('17:00')" class="date-time-clock-hrs">
                  05:00 PM
                </h5>
                <h5 @click="setLoginTime('18:00')" class="date-time-clock-hrs">
                  06:00 PM
                </h5>
                <h5 @click="setLoginTime('19:00')" class="date-time-clock-hrs">
                  07:00 PM
                </h5>
                <h5 @click="setLoginTime('20:00')" class="date-time-clock-hrs">
                  08:00 PM
                </h5>
                <h5 @click="setLoginTime('21:00')" class="date-time-clock-hrs">
                  09:00 PM
                </h5>
              </div>
              <input
                type="time"
                name="get_the_time"
                id="get_the_time"
                class="set_the_time_value form-control"
                v-model="loginDetails.loginTime"
              />
            </div>
          </div>
          <div class="p-col-2">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Logout
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setLogoutTime('08:00')" class="date-time-clock-hrs">
                08:00 AM
              </h5>
              <h5 @click="setLogoutTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setLogoutTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setLogoutTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setLogoutTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setLogoutTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setLogoutTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setLogoutTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
              <h5 @click="setLogoutTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
              <h5 @click="setLogoutTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setLogoutTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setLogoutTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
              <h5 @click="setLogoutTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
              <h5 @click="setLogoutTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="loginDetails.logoutTime"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        v-if="loginDetails.attendenceID == ''"
        label="Save Hours"
        icon="pi pi-check"
        class="p-button-primary"
        @click="addAssociateLogin"
      />
      <div class="p-text-right" v-if="loginDetails.attendenceID != ''">
        <!-- <Button
          label="Delete Hours"
          icon="pi pi-times"
          class="p-button-danger p-text-left"
          @click="deleteAssociateLogin"
        /> -->
        <Button
          label="Update Hours"
          icon="pi pi-check"
          class="p-button-primary"
          @click="updateAssociateLogin"
        />
      </div>
    </template>
  </Dialog>

  <Dialog
    v-model:visible="viewAttendanceDialog"
    :style="{ width: '70vw' }"
    :maximizable="true"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h5
        style="background-color:yellow"
        class="p-dialog-titlebar p-dialog-titlebar-icon"
      >
        {{ activeAssociateName }} Logged Hours
      </h5>
    </template>
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-mr-2">
        <b
          >Associate Name : {{ activeAssociateName }} ({{
            activeAssociateID
          }})</b
        >
      </div>
    </div>
    <hr />
    <DataTable
      :value="attendanceList"
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
    >
      <Column header="Store">
        <template #body="slotProps">
          {{ slotProps.data.branchName }} ({{ slotProps.data.branchId }})
        </template>
      </Column>
      <Column field="jobClass" header="Job Class"></Column>
      <Column header="Date">
        <template #body="slotProps">
          <b>{{ formatDate(slotProps.data.date) }}</b>
        </template>
      </Column>
      <Column header="Time IN">
        <template #body="slotProps">
          {{ formatTime(slotProps.data.timeIn) }}
        </template>
      </Column>
      <Column header="Time OUT">
        <template #body="slotProps">
          {{ formatTime(slotProps.data.timeOut) }}
        </template>
      </Column>
      <Column header="Added By">
        <template #body="slotProps">
          {{ slotProps.data.addedBy }}
        </template>
      </Column>
      <Column header="Action">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            :disabled="slotProps.data.branchId != currentStoreID || slotProps.data.timeOut != '' "
            @click="
              updateLoginTimes(
                slotProps.data.id,
                slotProps.data.timeOut,
                slotProps.data.timeIn,
                slotProps.data.date
              )
            "
          />
        </template>
      </Column>
    </DataTable>
  </Dialog>

  <Dialog
    v-model:visible="infoDialog"
    :closable="true"
    :style="{ width: '70vw' }"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Important Instructions
      </h5>
    </template>
    <p class="p-text-left">
      Important Instructions: <br />
      1. Before adjusting the associate hours confirm associates logged hours.
      <br />
      2. Make sure all the logged hours are log out properly. <br />
      3. Click Logged Hours Button to update or remove associate hours. <br />
      4. Once associate hours are approved it cannot be modified by any store
      except HQ. <br />
    </p>
  </Dialog>

  <Dialog
    v-model:visible="hoursPreviewDialog"
    :closable="true"
    :style="{ width: '100vw' }"
    position="top"
    class="p-fluid  p-m-0 p-dialog-maximized"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-calendar"></i>
        Associate Approved Hours
      </h5>
    </template>
    <DataTable
      :value="getAssociateLoggedHrs"
      :scrollable="true"
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
    >
      <Column header="Store">
        <template #body="slotProps">
          {{ slotProps.data.branchName }} ({{ slotProps.data.branchId }})
        </template>
      </Column>
      <Column header="Associate">
        <template #body="slotProps">
          <b>
            {{ slotProps.data.employeeName }} ({{ slotProps.data.employeeId }})
          </b>
        </template>
      </Column>
      <Column field="jobClass" header="Job Class"></Column>
      <Column header="Logged Date">
        <template #body="slotProps">
          <b>{{ formatDate(slotProps.data.loggedDate) }}</b>
        </template>
      </Column>
      <Column header="Time IN">
        <template #body="slotProps">
          {{ formatTime(slotProps.data.loggedIn) }}
        </template>
      </Column>
      <Column header="Time OUT">
        <template #body="slotProps">
          {{ formatTime(slotProps.data.loggedOut) }}
        </template>
      </Column>
      <Column field="notes" header="Notes"></Column>
      <Column field="payCode" header="Pay Code"></Column>
      <Column field="totalHrs" header="Total Hours"></Column>
      <Column field="breakTiming" header="Break Timing"></Column>
      <Column header="Approved Hours">
        <template #body="slotProps">
          <b>{{ formatAmount(slotProps.data.approveHrs) }}</b>
        </template>
      </Column>
      <Column field="brManagerId" header="Manager ID"></Column>
      <Column header="Created Date/Time">
        <template #body="slotProps">
          <b
            >{{ formatDate(slotProps.data.createdDate) }} /
            {{ formatTime(slotProps.data.createdTime) }}</b
          >
        </template>
      </Column>
    </DataTable>
    <h6 class="p-mt-2">
      <i class="pi pi-clock"></i> Total Approved Hours : {{formatAmount(getTotalAssociateLoggedHrs)}}
    </h6>
  </Dialog>

  <Dialog
    v-model:visible="hoursAdjustmentDialog"
    :closable="true"
    :style="{ width: '100vw' }"
    position="top"
    class="p-fluid  p-m-0 p-dialog-maximized"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-calendar"></i>
        Associate Adjust Approved Hours
      </h5>
    </template>
    <div class="p-grid">
      <div class="p-col-2 ">
        <div class="p-buttonset">
            <Button
              label="View Logged Hours"
              class="p-button-warning p-p-1 p-mr-2"
              @click="openAdjustmentLoggedHrs()"
              icon="pi pi-key"
            />
          </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 ">
        <Calendar
          v-model="adjustApproveHours.loginDate"
          :inline="true"
          :manualInput="false"
          dateFormat="yy-mm-dd"
        />
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-2">
        <Dropdown
          :options="paycodeList"
          optionLabel="name"
          optionValue="name"
          v-model="adjustApproveHours.payCode"
        />
      </div>
      <div class="p-col-3">
        <div class="p-inputgroup">
          <Button
            icon="pi pi-plus-circle"
            @click="adjustApproveHours.approveHrs++"
            class="p-button-success"
          />
          <InputNumber
            v-model="adjustApproveHours.approveHrs"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
          <Button
            icon="pi pi-minus-circle"
            @click="adjustApproveHours.approveHrs--"
            class="p-button-danger"
          />
        </div>
      </div>
      <div class="p-col-5">
        <InputText
          v-model="adjustApproveHours.notes"
          placeholder="Write any adjustment notes.."
        />
      </div>
      <div class="p-col-2">
        <Button class="p-button-success" icon="pi pi-check-circle" label="Save Adjustments"   @click="saveAdjustment()" />
      </div>
    </div>
  </Dialog>

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import router from "../../router";
import ApprovalService from "../../service/ApprovalService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import {
  ScheduleList,
  AttendanceList,
  ISavedHoursList,
  ILoggedHoursList,
} from "../hoursApproval/IHoursAppoval";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import SAMixin from "../../mixins/SAMixin";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  title: "Hours Approval",
  components: { AssociatePinVerification },
})
export default class HoursApproval extends mixins(SAMixin, UtilityOptions) {
  private ApprovalTxn;
  private scheduleID = "";
  private deleteStatus = "";
  private dialogTitle = "";

  private loginDetails = {
    title: "Add Associate Login Timings",
    attendenceID: "",
    loginTime: "",
    logoutTime: "",
    loginDate: "",
  };

  private schedule = {
    selectedPaycode: {
      name: "Regular",
    },
    timeIn: "",
    timeOut: "",
    notes: "",
    activeScheduleDate: "",
    totalLoggedHrs: "",
    loggedTimings: "",
    breakTimings: "",
    capacity: true,
  };

  private managerApproved = "";
  private loggedHoursList: ILoggedHoursList[] = [];
  private savedLoggedHoursList: ISavedHoursList[] = [];
  private associateAllApprovedHrs: ISavedHoursList[] = [];
  private savedLoggedID = "";
  private verificationType = "manager";
  private searchDate = "";
  private hoursPreviewDialog = false;
  private hoursAdjustmentDialog = false;
  private infoDialog = false;
  private viewAttendanceDialog = false;
  private loginHoursDialog = false;
  private showScheduleListStatus = false;
  private totalStoreDaily = 0;
  private totalStoreWeek = 0;
  private storeBreakHours = 0;
  private currentStoreID = "";
  private confirmLoggedHrs = false;
  private submitted = false;
  private approveBoxDialog = false;
  private manualApproveDailog = false;
  private toggleCheckBox = false;
  private activeAssociateID = "";
  private activeAssociateName = "";
  private activeAssociateJobClass = "";
  private weekStartingDate = "";
  private weekEndingDate = "";
  private weekNo = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Dashboard", to: "dashboard" },
    { label: "Hours approval" },
  ];
  private toast;
  private associateList: ScheduleList[] = [];
  private approvedList: ScheduleList[] = [];
  private attendanceList: AttendanceList[] = [];
  private approveManually = {
    hours: 0,
    freezed: false,
    shiftDate: "",
    counterId: 0,
  };

  private adjustApproveHours = {
    loginDate: '',
    payCode: 'Regular',
    approveHrs:0,
    notes: ''
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ApprovalTxn = new ApprovalService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getSchedule();
  }

  setLoginTime(dueTime) {
    this.loginDetails.loginTime = dueTime;
  }

  setLogoutTime(dueTime) {
    this.loginDetails.logoutTime = dueTime;
  }

  getSchedule() {
    this.ApprovalTxn.getAssociateShifts(this.searchDate).then((res) => {
      const d = this.camelizeKeys(res);
      const s = d.employeeList.scheduleList;
      const hoursList = d.storeHoursList.hoursList;
    
      if (s != null) {
        this.associateList = s;
      } else {
        this.associateList = [];
      }

      if (hoursList != null) {
        this.savedLoggedHoursList = hoursList;
        this.savedLoggedID = d.storeHoursList.id;
      } else {
        this.savedLoggedHoursList = [];
        this.savedLoggedID = "";
      }

      this.scheduleID = d.employeeList.id;
      this.weekStartingDate = d.postStartingDate;
      this.weekEndingDate = d.postEndingDate;
      this.weekNo = d.weekNo;
    });
  }

  searchSchedule() {
    this.showScheduleListStatus = false;
    this.getSchedule();
  }

  get filterAssociates() {
    let copyList: ScheduleList[] = [];

    if (this.associateList != null) {
      copyList = [...this.associateList];
    }

    const uniqueElementsBy = (arr, fn) =>
      arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);

    const list = uniqueElementsBy(
      copyList,
      (a, b) => a.employeeId == b.employeeId
    );

    list.map((e) => {
      e.aprrovedHrs = this.getTotalApprovedHrs(e.employeeId);
    });

    return list;
  }

  get noOfTotalApprovedHrs()
  {
    let total = 0;
    this.filterAssociates.map(e => {
        total = total + e.aprrovedHrs;
    });

    return total;
  }

  getTotalApprovedHrs(associateID) {
    let totalApproved = 0;
    if (this.savedLoggedHoursList != null) {
      this.savedLoggedHoursList.forEach((s) => {
        if (associateID == s.employeeId) {
          totalApproved = totalApproved + s.approveHrs;
        }
      });
    }

    return totalApproved;
  }

  openSchedule(empID, associateName, associateJobClass) {
    this.attendanceList = [];
    this.activeAssociateID = empID;
    this.activeAssociateName = associateName;
    this.activeAssociateJobClass = associateJobClass;

    this.ApprovalTxn.getAssociateLogin(
      empID,
      this.weekStartingDate,
      this.weekEndingDate
    )
      .then((res) => {
        return res;
      })
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.attendanceList = d.list;
        this.associateAllApprovedHrs = d.approvedList;
        this.totalStoreDaily = d.totalStoreDaily;
        this.totalStoreWeek = d.totalStoreWeek;
        this.storeBreakHours = d.storeBreakHours;
        this.currentStoreID = d.currentStoreId;
        this.setLoggedHrs();
        this.showScheduleListStatus = true;
      });
  }

  setLoggedHrs() {
    const sortedList = this.getSortedList;
    this.loggedHoursList = [];

    sortedList.forEach((e) => {
      const current = this.currentTimeDiffDecimal(e.timeOut, e.timeIn);

      //all previous week hours of all store of associate
      const WeekHrs = this.totalWeekRegularHrs();

      //before new hours
      const ExistDailyHrs = this.totalDailyRegularHrs(e.date);
      //ALL PREVIOUS PLUS NEW HOURS

      const NetWeekHrs = this.sumTwoDecimalTimes(current, WeekHrs);
      const NetDailyHrs = this.sumTwoDecimalTimes(current, ExistDailyHrs);

      if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs <= this.totalStoreDaily
      ) {
        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);
      } else if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs > this.totalStoreDaily &&
        ExistDailyHrs < this.totalStoreDaily
      ) {
        //WEEK HOURS IS FINE BUT DAILY HOUR IS OVERTIME
        const newTimeOut = this.newTimeHAOut(
          ExistDailyHrs,
          this.totalStoreDaily,
          e.timeIn
        );

        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: newTimeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);

        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: newTimeOut,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs >= this.totalStoreDaily
      ) {
        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (
        WeekHrs < this.totalStoreWeek &&
        NetWeekHrs > this.totalStoreWeek
      ) {
        const newTimeOut = this.newTimeHAOut(
          WeekHrs,
          this.totalStoreWeek,
          e.timeIn
        );

        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: newTimeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);

        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: newTimeOut,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (WeekHrs >= this.totalStoreWeek) {
        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      }
    });
  }

  get filterAssociatePaycodes() {
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });
    return schedule;
  }

  get sortedLoggedHrs() {
    this.filterWeekDates.forEach((e) => {
      const a = this.dateWiseAssignedHrs(e);
      const d = this.dateWiseLoggedHrs(e);
      const l = this.checkDayPayCodeLen(e);

      if (Number(a) < Number(d) && l <= 2 && this.deleteStatus == "") {
        const newH = d - a;
        const newTimeIn = this.lastTimeOutDate(e);

        if (newTimeIn != "") {
          const newTimeOut = moment(newTimeIn, "HH:mm")
            .add(newH, "hours")
            .format("HH:mm");

          const schDetail = {
            id: String("None" + Date.now()),
            loggedHrsDate: e,
            payCode: "None",
            loggedInHr: newTimeIn,
            loggedOutHr: newTimeOut,
            totalHrs: "",
            breakTiming: "",
            approveHrs: 0,
            notes: "",
            storeID: this.currentStoreID,
          };

          this.loggedHoursList.push(schDetail);
        }
      }
    });

    this.deleteStatus = "";

    this.loggedHoursList.forEach((e) => {
      if (e.loggedInHr != "" && e.loggedOutHr != "") {
        const b = this.checkBreakTimings(e);
        e.breakTiming = b;
        e.totalHrs = this.getTimeDiff(e.loggedOutHr, e.loggedInHr, b);
        e.approveHrs = this.getHoursInDecimal(e.loggedInHr, e.loggedOutHr, b);
      } else {
        e.breakTiming = "";
        e.totalHrs = "";
        e.approveHrs = 0;
      }
    });

  
    const l = this.loggedHoursList.sort((a, b) =>
      moment(a.loggedHrsDate + " " + a.loggedInHr, "YYYY-MM-DD HH:mm").isBefore(
        moment(b.loggedHrsDate + " " + b.loggedInHr, "YYYY-MM-DD HH:mm")
      )
        ? -1
        : 1
    );

    return l;
  }

  get filterWeekDates() {
    return [...new Set(this.attendanceList.map((item) => item.date))];
  }

  lastTimeOutDate(date) {
    let t = "";
    const d = this.loggedHoursList.filter(
      (e) =>
        this.checkSameDate(e.loggedHrsDate, date) && e.payCode != "Overtime"
    );

    if (d.length > 0) {
      t = moment(String(d[0].loggedOutHr), "HH:mm").format("HH:mm");
    }

    return t;
  }

  filterTotalLogin(empID, date) {
    let totalHrs = 0;

    this.attendanceList.forEach((e) => {
      if (
        e.employeeId == empID &&
        this.checkSameDate(date, e.date) &&
        e.timeOut != ""
      ) {
        const ms = moment(e.timeOut, "HH:mm").diff(moment(e.timeIn, "HH:mm"));
        const d = moment.duration(ms).asHours();
        totalHrs = totalHrs + d;
      }
    });

    return Math.round(totalHrs * 100) / 100;
  }

  totalAssignedHrs(empID, date) {
    let overAll = 0;

    this.associateList.forEach((e) => {
      if (
        e.employeeId == empID &&
        this.checkSameDate(date, e.shiftDate) &&
        e.timeOut != "" &&
        e.totalLoggedHrs != 0
      ) {
        overAll = overAll + e.totalLoggedHrs;
      }
    });

    return overAll;
  }

  approveAssociateHours() {
    this.ApprovalTxn.approveHours(
      this.loggedHoursList,
      this.managerApproved,
      this.activeAssociateID,
      this.activeAssociateName,
      this.activeAssociateJobClass,
      this.weekEndingDate,
      this.weekNo,
      this.savedLoggedID
    ).then((res) => {
      this.activeAssociateID = "";
      this.activeAssociateName = "";
      this.activeAssociateJobClass = "";
      this.toast.handleResponse(res);
      this.showScheduleListStatus = false;
      this.getSchedule();
    });
  }

  getLastTimeOut(shiftDate) {
    let timeOut = "";

    this.associateList.forEach((e) => {
      if (
        this.checkSameDate(e.shiftDate, shiftDate) &&
        e.employeeId == this.activeAssociateID
      ) {
        if (timeOut == "") {
          timeOut = e.timeOut;
        }

        if (timeOut < e.timeOut) {
          timeOut = e.timeOut;
        }
      }
    });

    return timeOut;
  }

  get checkScheduleContainsLogedHrs() {
    //ASSIGNING HRS
    this.associateList.forEach((a) => {
      if (a.employeeId == this.activeAssociateID) {
        const logedHrs = this.filterTotalLogin(a.employeeId, a.shiftDate);
        const currentSchHrs = this.filterCurrentSchHrs(
          a.timeIn,
          a.timeOut,
          a.breakTimings
        );

        const assignedHrs = this.totalAssignedHrs(a.employeeId, a.shiftDate);
        const remainingLogedHrs = this.formatAmount(logedHrs - assignedHrs);
        if (currentSchHrs <= remainingLogedHrs && remainingLogedHrs > 0) {
          a.totalLoggedHrs = currentSchHrs;
          a.loggedTimings = this.showAdjustedTime(a.timeIn, currentSchHrs);
        } else if (
          remainingLogedHrs <= currentSchHrs &&
          remainingLogedHrs > 0
        ) {
          a.totalLoggedHrs = remainingLogedHrs;
          a.loggedTimings = this.showAdjustedTime(a.timeIn, remainingLogedHrs);
        }
      }
    });

    //  //FILTERING ONLY ASSOCIATE
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });

    // sort the data by date using moment.js
    schedule.sort(function(left, right) {
      return moment(left.shiftDate).diff(moment(right.shiftDate));
    });

    return schedule;
  }

  openTimingDailog() {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }

  openAttendance() {
    this.viewAttendanceDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }
  
  openAdjustmentLoggedHrs() {

     this.ApprovalTxn.getAssociateLogin(
      this.activeAssociateID,
      this.weekStartingDate,
      this.weekEndingDate
    )
      .then((res) => {
        return res;
      })
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.attendanceList = d.list;
        this.currentStoreID = d.currentStoreId;
        this.viewAttendanceDialog = true;
      });
  }

  addAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.addAssociateLogin(
        this.loginDetails.loginTime,
        this.activeAssociateID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.postDate(this.loginDetails.loginDate),
        this.weekStartingDate,
        this.weekEndingDate
      ).then((res) => {
        this.toast.handleResponse(res);
        const d = this.camelizeKeys(res);
        if (d.data != null) {
          this.attendanceList = d.data;
          this.getSchedule();
          this.setLoggedHrs();
        }
      });
      this.loginHoursDialog = false;
    }
  }

  deleteAssociateLogin() {
    if (this.loginDetails.attendenceID == "") {
      this.toast.showWarning("Invalid attendance ID");
    } else {
      this.ApprovalTxn.deleteAssociateLogin(
        this.loginDetails.attendenceID
      ).then((res) => {
        this.toast.handleResponse(res);
        if (res.alert == "info") {
          this.attendanceList.forEach((e, i) => {
            if (e.id == this.loginDetails.attendenceID) {
              this.attendanceList.splice(i, 1);
              this.getSchedule();
              this.setLoggedHrs();
            }
          });
        }
      });
      this.loginHoursDialog = false;
    }
  }

  updateAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.updateAssociateLogin(
        this.loginDetails.loginTime,
        this.loginDetails.attendenceID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.postDate(this.loginDetails.loginDate),
        this.weekStartingDate,
        this.weekEndingDate
      ).then((res) => {
        this.toast.handleResponse(res);
        const d = this.camelizeKeys(res);
        this.attendanceList = d.data;
        this.getSchedule();
        this.setLoggedHrs();
      });
      this.loginHoursDialog = false;
    }
  }

  updateLoginTimes(id, timeOut, timeIn, shiftDate) {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Update Associate Login Timings";
    this.loginDetails.loginTime = timeIn;
    this.loginDetails.attendenceID = id;
    this.loginDetails.logoutTime = timeOut;
    this.loginDetails.loginDate = shiftDate;
  }

  closePINDialog(params) {
    this.managerApproved = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }

  checkSameDate(date1, date2) {
    date1 = moment(date1).toISOString(true);
    date2 = moment(date2).toISOString(true);

    let response = false;

    if (moment(date1).isSame(date2)) {
      response = true;
    } else {
      response = false;
    }

    return response;
  }

  getHoursInDecimal(loggedInHr, loggedOutHr, breakTimings) {
    if (breakTimings == "30 min") {
      loggedOutHr = moment(loggedOutHr, "HH:mm")
        .subtract(30, "minutes")
        .format("HH:mm");
    } else if (breakTimings == "60 min") {
      loggedOutHr = moment(loggedOutHr, "HH:mm")
        .subtract(60, "minutes")
        .format("HH:mm");
    }

    const ms = moment(loggedOutHr, "HH:mm").diff(moment(loggedInHr, "HH:mm"));
    return moment.duration(ms).asHours();
  }

  totalDailyRegularHrs(date) {
    let totalHrs = 0;
    this.loggedHoursList.forEach((e) => {
      if (this.checkSameDate(e.loggedHrsDate, date) && e.payCode == "Regular") {
        const ms = moment(e.loggedOutHr, "HH:mm").diff(
          moment(e.loggedInHr, "HH:mm")
        );
        totalHrs = totalHrs + moment.duration(ms).asHours();
      }
    });

    //COUNT APPROVED HOURS OF OTHER STORES
    this.associateAllApprovedHrs.forEach((e) => {
      if (
        e.branchId != this.currentStoreID &&
        this.checkSameDate(date, e.loggedDate)
      ) {
        totalHrs = totalHrs + Number(e.approveHrs);
      }
    });

    return totalHrs;
  }

  totalWeekRegularHrs() {
    let totalHrs = 0;

    this.loggedHoursList.forEach((e) => {
      if (e.payCode == "Regular") {
        const ms = moment(e.loggedOutHr, "HH:mm").diff(
          moment(e.loggedInHr, "HH:mm")
        );
        totalHrs = totalHrs + moment.duration(ms).asHours();
      }
    });

    //COUNT APPROVED HOURS OF OTHER STORES
    this.associateAllApprovedHrs.forEach((e) => {
      if (e.branchId != this.currentStoreID) {
        totalHrs = totalHrs + Number(e.approveHrs);
      }
    });

    return totalHrs;
  }

  dateWiseLoggedHrs(date) {
    let totalHrs = 0;
    this.attendanceList.forEach((e) => {
      if (e.branchId == this.currentStoreID) {
        if (e.timeIn != "" && e.timeOut != "") {
          if (this.checkSameDate(e.date, date) || date == "ALL") {
            const ms = moment(e.timeOut, "HH:mm").diff(
              moment(e.timeIn, "HH:mm")
            );
            totalHrs = totalHrs + moment.duration(ms).asHours();
          }
        }
      }
    });
    return this.formatAmount(totalHrs);
  }

  dateWiseAssignedHrs(date) {
    let totalHrs = 0;
    this.loggedHoursList.forEach((e) => {
      if (e.loggedInHr != "" && e.loggedOutHr != "") {
        if (this.checkSameDate(e.loggedHrsDate, date) || date == "ALL") {
          const ms = moment(e.loggedOutHr, "HH:mm").diff(
            moment(e.loggedInHr, "HH:mm")
          );
          totalHrs = totalHrs + moment.duration(ms).asHours();
        }
      }
    });

    return this.formatAmount(totalHrs);
  }

  checkDayPayCodeLen(date) {
    const p = this.loggedHoursList.filter((e) =>
      this.checkSameDate(e.loggedHrsDate, date)
    );
    return p.length;
  }

  sumTwoDecimalTimes(current, total) {
    const t = Number(current) + Number(total);
    return t;
  }

  get getSortedList() {
    const m = this.attendanceList.filter(
      (e) => e.branchId == this.currentStoreID
    );

    const l = m.sort((a, b) =>
      moment(a.date + " " + a.timeIn, "YYYY-MM-DD HH:mm").isBefore(
        moment(b.date + " " + b.timeIn, "YYYY-MM-DD HH:mm")
      )
        ? -1
        : 1
    );

    return l;
  }

  deleteHours(d) {
    const index = this.loggedHoursList.findIndex((e) => d.id == e.id);
    this.loggedHoursList.splice(index, 1);
    this.deleteStatus = "delete";
  }

  get nonePayCodeLen() {
    const t = this.loggedHoursList.filter((e) => e.payCode == "None");
    return t.length;
  }

  get getAssociateLoggedHrs() {
    return this.savedLoggedHoursList.filter(
      (e) => e.employeeId == this.activeAssociateID
    );
  }
  
  get getTotalAssociateLoggedHrs() {
    
    let t = 0;

    this.getAssociateLoggedHrs.map( e => {
        t = t + e.approveHrs;
      }
    );
    return t;
  }

  saveAdjustment()
  {
    if(this.adjustApproveHours.loginDate != '')
    {
      this.adjustApproveHours.loginDate = moment(this.adjustApproveHours.loginDate).format('YYYY-MM-DD');
      this.ApprovalTxn.adjustApproveHours(
        this.adjustApproveHours,
        this.managerApproved,
        this.savedLoggedID,
        this.activeAssociateName,
        this.activeAssociateID,
        this.activeAssociateJobClass,
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
        this.hoursAdjustmentDialog = false;

        this.adjustApproveHours.loginDate = '';
        this.adjustApproveHours.approveHrs = 0;
        this.adjustApproveHours.notes = '';
      });
    }
    else
    {
      this.toast.showWarning('Please choose Logged Date');
    }
  }

  openApprovedHrs(associateID,associateName) {
    this.activeAssociateID = associateID;
    this.activeAssociateID = associateID;
    this.activeAssociateName = associateName;
    this.hoursPreviewDialog = true;
  }

  openApprovedAdjustmentHrs(associateID,associateName,job) {
    this.activeAssociateID = associateID;
    this.activeAssociateName = associateName;
    this.activeAssociateJobClass = job;
    this.hoursAdjustmentDialog = true;
  }

  checkBreakTimings(e: ILoggedHoursList) {

    let breakTimes = "";

    const noBreakTime = this.loggedHoursList.filter(b => b.breakTiming == '' && b.loggedHrsDate == e.loggedHrsDate );
    const breakTime = this.loggedHoursList.filter(b => b.breakTiming != '' && b.loggedHrsDate == e.loggedHrsDate);

    let h =  0;

    const c =  Number(this.currentTimeDiffDecimal(e.loggedOutHr,e.loggedInHr));
    const for12 = (this.storeBreakHours*2)+1;

    if(e.payCode != "Regular" && e.payCode != "Overtime" && e.payCode != "Stat Holiday" && c >= for12)
    {
      breakTimes = "60 min";
    }
    else
    {
      breakTime.forEach(e => {
        const b = this.storeBreakHours + 0.50;
        let newTime = moment(e.loggedInHr, "HH:mm").add(String(b), "hours").format("HH:mm");
        h = h +  Number(this.currentTimeDiffDecimal(e.loggedOutHr,newTime));
      });
      
      noBreakTime.forEach(e => {
          h = h +  Number(this.currentTimeDiffDecimal(e.loggedOutHr,e.loggedInHr));
      });
      
      if(h > this.storeBreakHours )
      {
        breakTimes = "30 min";
      }
    }
    return breakTimes;
  }
}
</script>

<style scoped>
.highlighted {
  -webkit-animation: NAME-YOUR-ANIMATION 1s infinite; /* Safari 4+ */
  -moz-animation: NAME-YOUR-ANIMATION 1s infinite; /* Fx 5+ */
  -o-animation: NAME-YOUR-ANIMATION 1s infinite; /* Opera 12+ */
  animation: NAME-YOUR-ANIMATION 1s infinite; /* IE 10+, Fx 29+ */
  padding: 0px 15px;
  border-radius: 3px;
}

@-webkit-keyframes NAME-YOUR-ANIMATION {
  0%,
  49% {
    background-color: #004c97;
    border: 3px solid #004c97;
    color: #fff;
  }
  50%,
  100% {
    background-color: #c00;
    border: 3px solid #c00;
    color: #fff;
  }
}

.highlight-associate {
  background-color: #dc4535;
  color: #fff;
  padding: 5px;
  border-radius: 2px;
}

.ap-sch-hrs {
  background-color: #eacb00 !important;
}
.schedule-style td {
  padding: 10px;
}
</style>
