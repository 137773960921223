
import { Vue } from "vue-class-component";
export default class Initialization extends Vue {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Initialization" }];
  private initializationList = [
    {
      label: "Stores",
      image: require("@/assets/menu-icons/branch.png"),
      to: "/store/stores",
      description:
        "Add and Manage stores. You can add new store or edit existing store details plus add setup store details.",
    },
    {
      label: "Services",
      image: require("@/assets/menu-icons/services.png"),
      to: "/store/services",
      description:
        "Add and Manage services like Shirts, Dresses , Skirts to POS screen for your customers.",
    },
    {
      label: "Departments",
      image: require("@/assets/menu-icons/departments.png"),
      to: "/store/departments",
      description:
        "Add and Manage departments like Buttons, Hems , Seams to POS Screen for your customers.",
    },
    {
      label: "Items",
      image: require("@/assets/menu-icons/itemslits.png"),
      to: "/store/items",
      description:
        "Add and Manage items like Wears, Shorten Enclosed Hem, Shorten with Lace / Beads to POS screen for your customers.",
    },
    {
      label: "Item Popups",
      image: require("@/assets/menu-icons/item-details.png"),
      to: "/store/item-detail",
      description:
        "Add and Manage Extra item charges for item like if you want to add extra charge in pants services.",
    },
    {
      label: "UpCharge List",
      image: require("@/assets/menu-icons/upchargelist.png"),
      to: "/store/up-charge-lists",
      description:
        "Add extra charges upon specific services like 10% on Express.You can add other item tags here and manage those tags.",
    },
    {
      label: "Description Tags",
      image: require("@/assets/menu-icons/items.png"),
      to: "/store/description-tag",
      description:
        "Set colors, conditions and other tags like Male, Female, Red , Broken etc.You can create and manage exisiting tag..",
    },
    {
      label: "Coupons",
      image: require("@/assets/menu-icons/corporate_heads.png"),
      to: "/store/coupon",
      description:
        "Add and Manage coupons/discount for customers. Once you add from here it will appear on POS screen.",
    },
    {
      label: "Corporate Heads",
      image: require("@/assets/menu-icons/discount.png"),
      to: "/store/corporates-head",
      description:
        "Add and Manage corporate heads .You can create new corporates and their details to provide your services.",
    },
    {
      label: "Corporate Branches",
      image: require("@/assets/menu-icons/corporate.png"),
      to: "/store/corporate-braches",
      description:
        "Add and Manage corporate branches and their addresses located in different cities.",
    },
    {
      label: "Projected Sales",
      image: require("@/assets/menu-icons/projected_sales.png"),
      to: "/store/project-sales",
      description:
        "Set Weekly projected sales for a specific or current years.This will help you to achieve your weekly branch goals.",
    },
    {
      label: "Schedules",
      image: require("@/assets/menu-icons/schedule.png"),
      to: "/store/schedule-list",
      description:
        "Create Schedule for corporates and regular customers .You can manage schedules and assign schedules of stores or delete the assigned schedules.",
    },
    {
      label: "Price List",
      image: require("@/assets/menu-icons/pricelist.png"),
      to: "/store/price-list",
      description:
        "Set  Service, Departments and Item to schedules assigned to stores.You can copy the existing schedule or assign manually.",
    },
    {
      label: "Regions",
      image: require("@/assets/menu-icons/regions.png"),
      to: "/store/regions",
      description:
        "Set the store location regions and their country and manage the locations.It helps to avoid rewriting address in different sections like branches and dry cleaners.",
    },
    {
      label: "Dry Cleaners",
      image: require("@/assets/menu-icons/dryclean.png"),
      to: "/store/dry-cleaner",
      description:
        "Create new dry cleaners and manage existing once.You can set the drycleaners plus  their locations and percentages.",
    },
    {
      label: "Store Dry Cleaners",
      image: require("@/assets/menu-icons/drycleaner.png"),
      to: "/store/store-dry-cleaner",
      description:
        "Assign dry cleaners to the stores. You can assign dry cleaner to a multiple stores.",
    },
    {
      label: "Customers",
      image: require("@/assets/menu-icons/customers.png"),
      to: "/store/customers",
      description:
        "Search customers or create new or Manage existing one for all stitch it customers.You can set customers measurements and view their transaction history.",
    },
    {
      label: "Associate",
      image: require("@/assets/menu-icons/employee.png"),
      to: "/store/associates",
      description:
        "Search , Manage and Create new associate for branch you can assign multiple branches to each associate.",
    },
    {
      label: "Announcement",
      image: require("@/assets/menu-icons/speaker.png"),
      to: "/store/announcement",
      description:
        "Create and Manage announcements for all branches or specific branch.",
    },
    {
      label: "Manager Cash Register",
      image: require("@/assets/menu-icons/cash_register.png"),
      to: "/store/manager-cash-register",
      description:
        "Create and Manage cash register only manager can update this option if any changes required.",
    },
    {
      label: "Printer Setup",
      image: require("@/assets/menu-icons/printer.jpg"),
      to: "/store/printer-setup",
      description:
        "Create new printer and attach it to the application. you can set two types of printer from regular billing purpose and for dryclean purpose.",
    },
    {
      label: "Receipt Settings",
      image: require("@/assets/menu-icons/printer_receipt.jpg"),
      to: "/store/receipt-printer",
      description:
        "Add extra content under the store receipt . You can add or delete content for the store receipt from here.",
    },
    {
      label: "Survey",
      image: require("@/assets/menu-icons/polling.png"),
      to: "/store/survey",
      description:
        "Search, Create and Manage store wise.You can add or delete the survey.",
    },
  ];
}
