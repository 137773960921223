
import { Vue,Options } from "vue-class-component";
import UserAuthentication from "../../service/UserAuthentication";
import router from "../../router";
import Toaster from "../../helpers/Toaster";
import { useStore, ActionTypes } from "../../store";

@Options({
  components: {},
  title: 'Stitch It Login'
})

export default class Login extends Vue {
  private showProgress = false;
  private StoreID = "";
  private StorePassword = "";
  private errorMessage = "";
  private toast;

  created() {
    this.toast = new Toaster();
  }

  authenticate(e) {
    e.preventDefault();
    //this.StoreID = this.StoreID.trim();
    //this.StorePassword = this.StorePassword.trim();

    if (this.StoreID == "" || this.StorePassword == "") {
      this.errorMessage = "Please enter Store ID and Password";
    } else {
      this.errorMessage = "";
      const auth = new UserAuthentication();
      const store = useStore();

      auth.loginUser(this.StoreID, this.StorePassword).then((res) => {
        if (res.alert == "info") {
          this.StoreID = "";
          this.StorePassword = "";
          this.toast.handleResponse(res);
          store.dispatch(ActionTypes.AUTH_REQUEST, res.auth_token);
          router.replace({ path: "/store/dashboard", params: {} });
        } else {
          store.dispatch(ActionTypes.AUTH_LOGOUT, "");
          this.StoreID = "";
          this.StorePassword = "";
          this.toast.handleResponse(res);
        }
      });
    }
  }
}
