
import { Options, Vue } from "vue-class-component";
import ItemDetail from "../../service/ItemDetail";
import PriceListService from "../../service/PriceListService";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";


@Options({
  components: {},
})
export default class ItemDetails extends Vue {
  private imagePath = "";
  private lists = [];
  private ItemDetail;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Item Popups" },
  ];

  private sectionObj = [
    { key: "section_1", value: "Section 1" },
    { key: "section_2", value: "Section 2" },
  ];

  private product = {
    id: 0,
    itemID: "",
    itemDetailName: "",
    price: 0,
    sectionName: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;
  private selectedSectionValue = "";
  private selectedSectionObj;
  private selectedItemName = "";
  private selectedItemNameObj;
  private toast;
  private priceTxn;
  private serviceList = [];
  private departmentList = [];
    private itemList = [];
  private seletectedService = {
    id: "",
    name: "",
  };

  private seletectedDepart = {
    id: "",
    sector: "",
  };

  private selecteditem = {
    id: "",
    itemName: "",
  };

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);

    this.priceTxn.getPriceItemList().then((data) => {
      const d = this.camelizeKeys(data);
      this.serviceList = d.serviceList;
      this.departmentList = d.departmentList;
    });

  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  created() {
    this.ItemDetail = new ItemDetail();
     this.priceTxn = new PriceListService();
    this.imagePath = this.ItemDetail.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  setDropDownData() {
    this.sectionObj.filter((elem) => {
      if (elem.key == this.selectedSectionValue) {
        this.selectedSectionObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      itemID: "",
      itemDetailName: "",
      price: 0,
      sectionName: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add Item Details";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.itemDetailName.trim()) {
      if (this.product.id != 0) {
        this.product.itemID =  this.selecteditem.id;
        this.ItemDetail.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.product.itemID =  this.selecteditem.id;
        this.ItemDetail.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        itemID: "",
        itemDetailName: "",
        price: 0,
        sectionName: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Item Detail";
    this.productDialog = true;

    this.ItemDetail.getItem(data).then((res) => {
      if (res.length > 0) {

        this.selectedSectionValue = res[0].section_name;

        this.setDropDownData();

        this.selecteditem.id = res[0].item_id;
        
        this.product = {
          id: res[0].item_sp_id,
          itemID: res[0].item_id,
          itemDetailName: res[0].item_sp_name,
          price: Number(res[0].item_sp_price),
          sectionName: this.selectedSectionObj,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.item_sp_id,
      itemID: "",
      itemDetailName: data.item_sp_name,
      price: 0,
      sectionName: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.ItemDetail.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.ItemDetail.getItems(page).then((data) => {
      this.lists = data.item_details;
      this.totalRecords = data.total_item_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.loading = true;
    this.submitted = true;
    if (this.keyword) {
      this.ItemDetail.getSearchedItemDetails(this.keyword).then((data) => {
        this.lists = data.item_details;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }

  fetchItems() {
    if (this.seletectedService.id != "" && this.seletectedDepart.id != "") {
      this.priceTxn
        .getItemList(this.seletectedService.id, this.seletectedDepart.id)
        .then((data) => {
          const item = this.camelizeKeys(data);
          this.itemList = item;
        });
    }
  }
}
