<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :loading="loading"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="item_name" header="Name"></Column>
          <Column field="service_name" header="Service"></Column>
          <Column field="sector" header="Department"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="service">Service</label>
          <Dropdown
            v-model="product.service"
            :options="allServices"
            optionLabel="name"
            :filter="true"
            placeholder="Select a Service"
          />
        </div>
        <div class="p-field">
          <label for="department">Department</label>
          <Dropdown
            v-model="product.department"
            :options="allDepartments"
            optionLabel="sector"
            :filter="true"
            placeholder="Select a Department"
          />
        </div>
        <div class="p-field">
          <label for="itemTypes">Item Type</label>
          <Dropdown
            v-model="product.itemType"
            :options="itemTypes"
            optionLabel="key"
          />
        </div>
        <div class="p-field">
          <label for="name">Name</label>
          <InputText
            id="name"
            v-model.trim="product.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.name"
            >Name is required.</small
          >
        </div>
        <div class="p-field">
          <label for="popupCondition">Has Popup Conditions:</label>
          <Dropdown
            v-model="product.hasPopUpCondition"
            :options="popupConditions"
            optionLabel="key"
          />
        </div>
        <h5>
            Is it On Rack ?
        </h5>
        <div class="d-flex justify-content-start pt-3">
          <div class="p-field-radiobutton mx-3">
            <RadioButton
              class="mx-2"
              id="not_rack"
              value="not_rack"
              v-model="product.rack"
            />
            <label for="not_rack">Not Rack</label>
          </div>

          <div class="p-field-radiobutton">
            <RadioButton id="onRack" value="rack" v-model="product.rack" />
            <label for="onRack" class="mx-2">On The Rack</label>
          </div>
        </div>

         <h5>Tax Exemption</h5>
        <div class="d-flex justify-content-start pt-3">
          <div class="p-field-radiobutton mx-3">
            <RadioButton
              class="mx-2"
              id="not_exempt"
              value="not_exempt"
              v-model="product.taxes"
            />
            <label for="not_exempt">Not Exempt</label>
          </div>

          <div class="p-field-radiobutton mx-3">
            <RadioButton
              class="mx-2"
              id="both_exampt"
              value="Both Ex"
              v-model="product.taxes"
            />
            <label for="both_exampt">Both Exempt </label>
          </div>

          <div class="p-field-radiobutton mx-3">
            <RadioButton
              class="mx-2"
              id="federal_exampt"
              value="Fd Ex"
              v-model="product.taxes"
            />
            <label for="federal_exampt">Federal Exempt </label>
          </div>

          <div class="p-field-radiobutton mx-3">
            <RadioButton
              class="mx-2"
              id="provincial_exampt"
              value="Prov Ex"
              v-model="product.taxes"
            />
            <label for="provincial_exampt">Provincial Exempt </label>
          </div>

        </div>

        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>
      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product">
            Change the status of <b>{{ product.name }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Item from "../../service/Item";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Items extends Vue {
  private imagePath = "";
  private lists = [];
  private allServices;
  private allDepartments;
  private item;
  private productStatus = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private keyword = "";
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Items" },
  ];

  private itemTypes = [{ key: "Product" }, { key: "Service" }];

  private popupConditions = [{ key: "No" }, { key: "Yes" }];

  private product = {
    id: 0,
    name: "",
    service: "",
    department: "",
    hasPopUpCondition: "",
    itemType: "",
    rack: "not_rack",
    status: "",
    taxes: "not_exempt",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private serviceId = "";
  private serviceObj;

  private departmentId = "";
  private departmentObj;

  private selectedPopConditionKey = "";
  private selectedPopConditionObj;

  private selectedItemTypeKey = "";
  private selectedItemTypeObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
    this.loadServicesAndDepartment();
  }

  created() {
    this.item = new Item();
    this.imagePath = this.item.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //lOAD SERVICES AND DEPARTMENTS FOR DROPDOWN FIELD
  loadServicesAndDepartment() {
    this.item.getServicesAndDepartments().then((data) => {
      this.allServices = data.services;
      this.allDepartments = data.departments;
    });
  }

  setServiceAndDepartment() {
    this.allServices.filter((elem) => {
      if (elem.id == this.serviceId) {
        this.serviceObj = elem;
      }
    });

    this.allDepartments.filter((elem) => {
      if (elem.id == this.departmentId) {
        this.departmentObj = elem;
      }
    });

    this.popupConditions.filter((elem) => {
      if (elem.key == this.selectedPopConditionKey) {
        this.selectedPopConditionObj = elem;
      }
    });

    this.itemTypes.filter((elem) => {
      if (elem.key == this.selectedItemTypeKey) {
        this.selectedItemTypeObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      service: "",
      department: "",
      hasPopUpCondition: "",
      itemType: "",
      rack: "not_rack",
      status: "",
      taxes: "not_exempt",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Item";
    this.productDialog = true;
    this.checkPagination = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.item.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.item.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        service: "",
        department: "",
        hasPopUpCondition: "",
        itemType: "",
        rack: "not_rack",
        status: "",
        taxes: "not_exempt",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Items";
    this.productDialog = true;
    this.checkPagination = true;

    this.item.getItem(data).then((res) => {
      if (res.length > 0) {
        this.serviceId = res[0].service_id;
        this.departmentId = res[0].department_id;
        this.selectedPopConditionKey = res[0].has_condition;
        this.selectedItemTypeKey = res[0].item_type;
        this.setServiceAndDepartment();
        this.product = {
          id: res[0].id,
          name: res[0].item_name,
          service: this.serviceObj,
          department: this.departmentObj,
          hasPopUpCondition: this.selectedPopConditionObj,
          itemType: this.selectedItemTypeObj,
          rack: res[0].rack,
          status: res[0].status,
          taxes: res[0].taxes,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.item_name,
      service: "",
      department: "",
      hasPopUpCondition: "",
      itemType: "",
      rack: "",
      status: data.status,
      taxes: "not_exampt",
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.item.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.item.getItems(page).then((data) => {
      this.lists = data.items_list;
      this.totalRecords = data.total_item_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.item.getSearchedItems(this.keyword).then((data) => {
        this.lists = data.items_list;
        // this.totalRecords = data.total_item_count;
        // this.limit = data.limit_per_page;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>