
import { Options, mixins } from "vue-class-component";
import router from "../../router";
import ApprovalService from "../../service/ApprovalService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import {
  ScheduleList,
  AttendanceList,
  ISavedHoursList,
  ILoggedHoursList,
} from "../hoursApproval/IHoursAppoval";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import SAMixin from "../../mixins/SAMixin";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  title: "Hours Approval",
  components: { AssociatePinVerification },
})
export default class HoursApproval extends mixins(SAMixin, UtilityOptions) {
  private ApprovalTxn;
  private scheduleID = "";
  private deleteStatus = "";
  private dialogTitle = "";

  private loginDetails = {
    title: "Add Associate Login Timings",
    attendenceID: "",
    loginTime: "",
    logoutTime: "",
    loginDate: "",
  };

  private schedule = {
    selectedPaycode: {
      name: "Regular",
    },
    timeIn: "",
    timeOut: "",
    notes: "",
    activeScheduleDate: "",
    totalLoggedHrs: "",
    loggedTimings: "",
    breakTimings: "",
    capacity: true,
  };

  private managerApproved = "";
  private loggedHoursList: ILoggedHoursList[] = [];
  private savedLoggedHoursList: ISavedHoursList[] = [];
  private associateAllApprovedHrs: ISavedHoursList[] = [];
  private savedLoggedID = "";
  private verificationType = "manager";
  private searchDate = "";
  private hoursPreviewDialog = false;
  private hoursAdjustmentDialog = false;
  private infoDialog = false;
  private viewAttendanceDialog = false;
  private loginHoursDialog = false;
  private showScheduleListStatus = false;
  private totalStoreDaily = 0;
  private totalStoreWeek = 0;
  private storeBreakHours = 0;
  private currentStoreID = "";
  private confirmLoggedHrs = false;
  private submitted = false;
  private approveBoxDialog = false;
  private manualApproveDailog = false;
  private toggleCheckBox = false;
  private activeAssociateID = "";
  private activeAssociateName = "";
  private activeAssociateJobClass = "";
  private weekStartingDate = "";
  private weekEndingDate = "";
  private weekNo = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Dashboard", to: "dashboard" },
    { label: "Hours approval" },
  ];
  private toast;
  private associateList: ScheduleList[] = [];
  private approvedList: ScheduleList[] = [];
  private attendanceList: AttendanceList[] = [];
  private approveManually = {
    hours: 0,
    freezed: false,
    shiftDate: "",
    counterId: 0,
  };

  private adjustApproveHours = {
    loginDate: '',
    payCode: 'Regular',
    approveHrs:0,
    notes: ''
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ApprovalTxn = new ApprovalService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getSchedule();
  }

  setLoginTime(dueTime) {
    this.loginDetails.loginTime = dueTime;
  }

  setLogoutTime(dueTime) {
    this.loginDetails.logoutTime = dueTime;
  }

  getSchedule() {
    this.ApprovalTxn.getAssociateShifts(this.searchDate).then((res) => {
      const d = this.camelizeKeys(res);
      const s = d.employeeList.scheduleList;
      const hoursList = d.storeHoursList.hoursList;
    
      if (s != null) {
        this.associateList = s;
      } else {
        this.associateList = [];
      }

      if (hoursList != null) {
        this.savedLoggedHoursList = hoursList;
        this.savedLoggedID = d.storeHoursList.id;
      } else {
        this.savedLoggedHoursList = [];
        this.savedLoggedID = "";
      }

      this.scheduleID = d.employeeList.id;
      this.weekStartingDate = d.postStartingDate;
      this.weekEndingDate = d.postEndingDate;
      this.weekNo = d.weekNo;
    });
  }

  searchSchedule() {
    this.showScheduleListStatus = false;
    this.getSchedule();
  }

  get filterAssociates() {
    let copyList: ScheduleList[] = [];

    if (this.associateList != null) {
      copyList = [...this.associateList];
    }

    const uniqueElementsBy = (arr, fn) =>
      arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);

    const list = uniqueElementsBy(
      copyList,
      (a, b) => a.employeeId == b.employeeId
    );

    list.map((e) => {
      e.aprrovedHrs = this.getTotalApprovedHrs(e.employeeId);
    });

    return list;
  }

  get noOfTotalApprovedHrs()
  {
    let total = 0;
    this.filterAssociates.map(e => {
        total = total + e.aprrovedHrs;
    });

    return total;
  }

  getTotalApprovedHrs(associateID) {
    let totalApproved = 0;
    if (this.savedLoggedHoursList != null) {
      this.savedLoggedHoursList.forEach((s) => {
        if (associateID == s.employeeId) {
          totalApproved = totalApproved + s.approveHrs;
        }
      });
    }

    return totalApproved;
  }

  openSchedule(empID, associateName, associateJobClass) {
    this.attendanceList = [];
    this.activeAssociateID = empID;
    this.activeAssociateName = associateName;
    this.activeAssociateJobClass = associateJobClass;

    this.ApprovalTxn.getAssociateLogin(
      empID,
      this.weekStartingDate,
      this.weekEndingDate
    )
      .then((res) => {
        return res;
      })
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.attendanceList = d.list;
        this.associateAllApprovedHrs = d.approvedList;
        this.totalStoreDaily = d.totalStoreDaily;
        this.totalStoreWeek = d.totalStoreWeek;
        this.storeBreakHours = d.storeBreakHours;
        this.currentStoreID = d.currentStoreId;
        this.setLoggedHrs();
        this.showScheduleListStatus = true;
      });
  }

  setLoggedHrs() {
    const sortedList = this.getSortedList;
    this.loggedHoursList = [];

    sortedList.forEach((e) => {
      const current = this.currentTimeDiffDecimal(e.timeOut, e.timeIn);

      //all previous week hours of all store of associate
      const WeekHrs = this.totalWeekRegularHrs();

      //before new hours
      const ExistDailyHrs = this.totalDailyRegularHrs(e.date);
      //ALL PREVIOUS PLUS NEW HOURS

      const NetWeekHrs = this.sumTwoDecimalTimes(current, WeekHrs);
      const NetDailyHrs = this.sumTwoDecimalTimes(current, ExistDailyHrs);

      if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs <= this.totalStoreDaily
      ) {
        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);
      } else if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs > this.totalStoreDaily &&
        ExistDailyHrs < this.totalStoreDaily
      ) {
        //WEEK HOURS IS FINE BUT DAILY HOUR IS OVERTIME
        const newTimeOut = this.newTimeHAOut(
          ExistDailyHrs,
          this.totalStoreDaily,
          e.timeIn
        );

        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: newTimeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);

        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: newTimeOut,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (
        NetWeekHrs <= this.totalStoreWeek &&
        NetDailyHrs >= this.totalStoreDaily
      ) {
        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (
        WeekHrs < this.totalStoreWeek &&
        NetWeekHrs > this.totalStoreWeek
      ) {
        const newTimeOut = this.newTimeHAOut(
          WeekHrs,
          this.totalStoreWeek,
          e.timeIn
        );

        const schDetail = {
          id: String("Regular" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Regular",
          loggedInHr: e.timeIn,
          loggedOutHr: newTimeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetail);

        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: newTimeOut,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      } else if (WeekHrs >= this.totalStoreWeek) {
        const schDetailOT = {
          id: String("Overtime" + Date.now()),
          loggedHrsDate: e.date,
          payCode: "Overtime",
          loggedInHr: e.timeIn,
          loggedOutHr: e.timeOut,
          totalHrs: "",
          breakTiming: "",
          approveHrs: 0,
          notes: "",
          storeID: this.currentStoreID,
        };

        this.loggedHoursList.push(schDetailOT);
      }
    });
  }

  get filterAssociatePaycodes() {
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });
    return schedule;
  }

  get sortedLoggedHrs() {
    this.filterWeekDates.forEach((e) => {
      const a = this.dateWiseAssignedHrs(e);
      const d = this.dateWiseLoggedHrs(e);
      const l = this.checkDayPayCodeLen(e);

      if (Number(a) < Number(d) && l <= 2 && this.deleteStatus == "") {
        const newH = d - a;
        const newTimeIn = this.lastTimeOutDate(e);

        if (newTimeIn != "") {
          const newTimeOut = moment(newTimeIn, "HH:mm")
            .add(newH, "hours")
            .format("HH:mm");

          const schDetail = {
            id: String("None" + Date.now()),
            loggedHrsDate: e,
            payCode: "None",
            loggedInHr: newTimeIn,
            loggedOutHr: newTimeOut,
            totalHrs: "",
            breakTiming: "",
            approveHrs: 0,
            notes: "",
            storeID: this.currentStoreID,
          };

          this.loggedHoursList.push(schDetail);
        }
      }
    });

    this.deleteStatus = "";

    this.loggedHoursList.forEach((e) => {
      if (e.loggedInHr != "" && e.loggedOutHr != "") {
        const b = this.checkBreakTimings(e);
        e.breakTiming = b;
        e.totalHrs = this.getTimeDiff(e.loggedOutHr, e.loggedInHr, b);
        e.approveHrs = this.getHoursInDecimal(e.loggedInHr, e.loggedOutHr, b);
      } else {
        e.breakTiming = "";
        e.totalHrs = "";
        e.approveHrs = 0;
      }
    });

  
    const l = this.loggedHoursList.sort((a, b) =>
      moment(a.loggedHrsDate + " " + a.loggedInHr, "YYYY-MM-DD HH:mm").isBefore(
        moment(b.loggedHrsDate + " " + b.loggedInHr, "YYYY-MM-DD HH:mm")
      )
        ? -1
        : 1
    );

    return l;
  }

  get filterWeekDates() {
    return [...new Set(this.attendanceList.map((item) => item.date))];
  }

  lastTimeOutDate(date) {
    let t = "";
    const d = this.loggedHoursList.filter(
      (e) =>
        this.checkSameDate(e.loggedHrsDate, date) && e.payCode != "Overtime"
    );

    if (d.length > 0) {
      t = moment(String(d[0].loggedOutHr), "HH:mm").format("HH:mm");
    }

    return t;
  }

  filterTotalLogin(empID, date) {
    let totalHrs = 0;

    this.attendanceList.forEach((e) => {
      if (
        e.employeeId == empID &&
        this.checkSameDate(date, e.date) &&
        e.timeOut != ""
      ) {
        const ms = moment(e.timeOut, "HH:mm").diff(moment(e.timeIn, "HH:mm"));
        const d = moment.duration(ms).asHours();
        totalHrs = totalHrs + d;
      }
    });

    return Math.round(totalHrs * 100) / 100;
  }

  totalAssignedHrs(empID, date) {
    let overAll = 0;

    this.associateList.forEach((e) => {
      if (
        e.employeeId == empID &&
        this.checkSameDate(date, e.shiftDate) &&
        e.timeOut != "" &&
        e.totalLoggedHrs != 0
      ) {
        overAll = overAll + e.totalLoggedHrs;
      }
    });

    return overAll;
  }

  approveAssociateHours() {
    this.ApprovalTxn.approveHours(
      this.loggedHoursList,
      this.managerApproved,
      this.activeAssociateID,
      this.activeAssociateName,
      this.activeAssociateJobClass,
      this.weekEndingDate,
      this.weekNo,
      this.savedLoggedID
    ).then((res) => {
      this.activeAssociateID = "";
      this.activeAssociateName = "";
      this.activeAssociateJobClass = "";
      this.toast.handleResponse(res);
      this.showScheduleListStatus = false;
      this.getSchedule();
    });
  }

  getLastTimeOut(shiftDate) {
    let timeOut = "";

    this.associateList.forEach((e) => {
      if (
        this.checkSameDate(e.shiftDate, shiftDate) &&
        e.employeeId == this.activeAssociateID
      ) {
        if (timeOut == "") {
          timeOut = e.timeOut;
        }

        if (timeOut < e.timeOut) {
          timeOut = e.timeOut;
        }
      }
    });

    return timeOut;
  }

  get checkScheduleContainsLogedHrs() {
    //ASSIGNING HRS
    this.associateList.forEach((a) => {
      if (a.employeeId == this.activeAssociateID) {
        const logedHrs = this.filterTotalLogin(a.employeeId, a.shiftDate);
        const currentSchHrs = this.filterCurrentSchHrs(
          a.timeIn,
          a.timeOut,
          a.breakTimings
        );

        const assignedHrs = this.totalAssignedHrs(a.employeeId, a.shiftDate);
        const remainingLogedHrs = this.formatAmount(logedHrs - assignedHrs);
        if (currentSchHrs <= remainingLogedHrs && remainingLogedHrs > 0) {
          a.totalLoggedHrs = currentSchHrs;
          a.loggedTimings = this.showAdjustedTime(a.timeIn, currentSchHrs);
        } else if (
          remainingLogedHrs <= currentSchHrs &&
          remainingLogedHrs > 0
        ) {
          a.totalLoggedHrs = remainingLogedHrs;
          a.loggedTimings = this.showAdjustedTime(a.timeIn, remainingLogedHrs);
        }
      }
    });

    //  //FILTERING ONLY ASSOCIATE
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });

    // sort the data by date using moment.js
    schedule.sort(function(left, right) {
      return moment(left.shiftDate).diff(moment(right.shiftDate));
    });

    return schedule;
  }

  openTimingDailog() {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }

  openAttendance() {
    this.viewAttendanceDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }
  
  openAdjustmentLoggedHrs() {

     this.ApprovalTxn.getAssociateLogin(
      this.activeAssociateID,
      this.weekStartingDate,
      this.weekEndingDate
    )
      .then((res) => {
        return res;
      })
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.attendanceList = d.list;
        this.currentStoreID = d.currentStoreId;
        this.viewAttendanceDialog = true;
      });
  }

  addAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.addAssociateLogin(
        this.loginDetails.loginTime,
        this.activeAssociateID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.postDate(this.loginDetails.loginDate),
        this.weekStartingDate,
        this.weekEndingDate
      ).then((res) => {
        this.toast.handleResponse(res);
        const d = this.camelizeKeys(res);
        if (d.data != null) {
          this.attendanceList = d.data;
          this.getSchedule();
          this.setLoggedHrs();
        }
      });
      this.loginHoursDialog = false;
    }
  }

  deleteAssociateLogin() {
    if (this.loginDetails.attendenceID == "") {
      this.toast.showWarning("Invalid attendance ID");
    } else {
      this.ApprovalTxn.deleteAssociateLogin(
        this.loginDetails.attendenceID
      ).then((res) => {
        this.toast.handleResponse(res);
        if (res.alert == "info") {
          this.attendanceList.forEach((e, i) => {
            if (e.id == this.loginDetails.attendenceID) {
              this.attendanceList.splice(i, 1);
              this.getSchedule();
              this.setLoggedHrs();
            }
          });
        }
      });
      this.loginHoursDialog = false;
    }
  }

  updateAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.updateAssociateLogin(
        this.loginDetails.loginTime,
        this.loginDetails.attendenceID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.postDate(this.loginDetails.loginDate),
        this.weekStartingDate,
        this.weekEndingDate
      ).then((res) => {
        this.toast.handleResponse(res);
        const d = this.camelizeKeys(res);
        this.attendanceList = d.data;
        this.getSchedule();
        this.setLoggedHrs();
      });
      this.loginHoursDialog = false;
    }
  }

  updateLoginTimes(id, timeOut, timeIn, shiftDate) {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Update Associate Login Timings";
    this.loginDetails.loginTime = timeIn;
    this.loginDetails.attendenceID = id;
    this.loginDetails.logoutTime = timeOut;
    this.loginDetails.loginDate = shiftDate;
  }

  closePINDialog(params) {
    this.managerApproved = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }

  checkSameDate(date1, date2) {
    date1 = moment(date1).toISOString(true);
    date2 = moment(date2).toISOString(true);

    let response = false;

    if (moment(date1).isSame(date2)) {
      response = true;
    } else {
      response = false;
    }

    return response;
  }

  getHoursInDecimal(loggedInHr, loggedOutHr, breakTimings) {
    if (breakTimings == "30 min") {
      loggedOutHr = moment(loggedOutHr, "HH:mm")
        .subtract(30, "minutes")
        .format("HH:mm");
    } else if (breakTimings == "60 min") {
      loggedOutHr = moment(loggedOutHr, "HH:mm")
        .subtract(60, "minutes")
        .format("HH:mm");
    }

    const ms = moment(loggedOutHr, "HH:mm").diff(moment(loggedInHr, "HH:mm"));
    return moment.duration(ms).asHours();
  }

  totalDailyRegularHrs(date) {
    let totalHrs = 0;
    this.loggedHoursList.forEach((e) => {
      if (this.checkSameDate(e.loggedHrsDate, date) && e.payCode == "Regular") {
        const ms = moment(e.loggedOutHr, "HH:mm").diff(
          moment(e.loggedInHr, "HH:mm")
        );
        totalHrs = totalHrs + moment.duration(ms).asHours();
      }
    });

    //COUNT APPROVED HOURS OF OTHER STORES
    this.associateAllApprovedHrs.forEach((e) => {
      if (
        e.branchId != this.currentStoreID &&
        this.checkSameDate(date, e.loggedDate)
      ) {
        totalHrs = totalHrs + Number(e.approveHrs);
      }
    });

    return totalHrs;
  }

  totalWeekRegularHrs() {
    let totalHrs = 0;

    this.loggedHoursList.forEach((e) => {
      if (e.payCode == "Regular") {
        const ms = moment(e.loggedOutHr, "HH:mm").diff(
          moment(e.loggedInHr, "HH:mm")
        );
        totalHrs = totalHrs + moment.duration(ms).asHours();
      }
    });

    //COUNT APPROVED HOURS OF OTHER STORES
    this.associateAllApprovedHrs.forEach((e) => {
      if (e.branchId != this.currentStoreID) {
        totalHrs = totalHrs + Number(e.approveHrs);
      }
    });

    return totalHrs;
  }

  dateWiseLoggedHrs(date) {
    let totalHrs = 0;
    this.attendanceList.forEach((e) => {
      if (e.branchId == this.currentStoreID) {
        if (e.timeIn != "" && e.timeOut != "") {
          if (this.checkSameDate(e.date, date) || date == "ALL") {
            const ms = moment(e.timeOut, "HH:mm").diff(
              moment(e.timeIn, "HH:mm")
            );
            totalHrs = totalHrs + moment.duration(ms).asHours();
          }
        }
      }
    });
    return this.formatAmount(totalHrs);
  }

  dateWiseAssignedHrs(date) {
    let totalHrs = 0;
    this.loggedHoursList.forEach((e) => {
      if (e.loggedInHr != "" && e.loggedOutHr != "") {
        if (this.checkSameDate(e.loggedHrsDate, date) || date == "ALL") {
          const ms = moment(e.loggedOutHr, "HH:mm").diff(
            moment(e.loggedInHr, "HH:mm")
          );
          totalHrs = totalHrs + moment.duration(ms).asHours();
        }
      }
    });

    return this.formatAmount(totalHrs);
  }

  checkDayPayCodeLen(date) {
    const p = this.loggedHoursList.filter((e) =>
      this.checkSameDate(e.loggedHrsDate, date)
    );
    return p.length;
  }

  sumTwoDecimalTimes(current, total) {
    const t = Number(current) + Number(total);
    return t;
  }

  get getSortedList() {
    const m = this.attendanceList.filter(
      (e) => e.branchId == this.currentStoreID
    );

    const l = m.sort((a, b) =>
      moment(a.date + " " + a.timeIn, "YYYY-MM-DD HH:mm").isBefore(
        moment(b.date + " " + b.timeIn, "YYYY-MM-DD HH:mm")
      )
        ? -1
        : 1
    );

    return l;
  }

  deleteHours(d) {
    const index = this.loggedHoursList.findIndex((e) => d.id == e.id);
    this.loggedHoursList.splice(index, 1);
    this.deleteStatus = "delete";
  }

  get nonePayCodeLen() {
    const t = this.loggedHoursList.filter((e) => e.payCode == "None");
    return t.length;
  }

  get getAssociateLoggedHrs() {
    return this.savedLoggedHoursList.filter(
      (e) => e.employeeId == this.activeAssociateID
    );
  }
  
  get getTotalAssociateLoggedHrs() {
    
    let t = 0;

    this.getAssociateLoggedHrs.map( e => {
        t = t + e.approveHrs;
      }
    );
    return t;
  }

  saveAdjustment()
  {
    if(this.adjustApproveHours.loginDate != '')
    {
      this.adjustApproveHours.loginDate = moment(this.adjustApproveHours.loginDate).format('YYYY-MM-DD');
      this.ApprovalTxn.adjustApproveHours(
        this.adjustApproveHours,
        this.managerApproved,
        this.savedLoggedID,
        this.activeAssociateName,
        this.activeAssociateID,
        this.activeAssociateJobClass,
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
        this.hoursAdjustmentDialog = false;

        this.adjustApproveHours.loginDate = '';
        this.adjustApproveHours.approveHrs = 0;
        this.adjustApproveHours.notes = '';
      });
    }
    else
    {
      this.toast.showWarning('Please choose Logged Date');
    }
  }

  openApprovedHrs(associateID,associateName) {
    this.activeAssociateID = associateID;
    this.activeAssociateID = associateID;
    this.activeAssociateName = associateName;
    this.hoursPreviewDialog = true;
  }

  openApprovedAdjustmentHrs(associateID,associateName,job) {
    this.activeAssociateID = associateID;
    this.activeAssociateName = associateName;
    this.activeAssociateJobClass = job;
    this.hoursAdjustmentDialog = true;
  }

  checkBreakTimings(e: ILoggedHoursList) {

    let breakTimes = "";

    const noBreakTime = this.loggedHoursList.filter(b => b.breakTiming == '' && b.loggedHrsDate == e.loggedHrsDate );
    const breakTime = this.loggedHoursList.filter(b => b.breakTiming != '' && b.loggedHrsDate == e.loggedHrsDate);

    let h =  0;

    const c =  Number(this.currentTimeDiffDecimal(e.loggedOutHr,e.loggedInHr));
    const for12 = (this.storeBreakHours*2)+1;

    if(e.payCode != "Regular" && e.payCode != "Overtime" && e.payCode != "Stat Holiday" && c >= for12)
    {
      breakTimes = "60 min";
    }
    else
    {
      breakTime.forEach(e => {
        const b = this.storeBreakHours + 0.50;
        let newTime = moment(e.loggedInHr, "HH:mm").add(String(b), "hours").format("HH:mm");
        h = h +  Number(this.currentTimeDiffDecimal(e.loggedOutHr,newTime));
      });
      
      noBreakTime.forEach(e => {
          h = h +  Number(this.currentTimeDiffDecimal(e.loggedOutHr,e.loggedInHr));
      });
      
      if(h > this.storeBreakHours )
      {
        breakTimes = "30 min";
      }
    }
    return breakTimes;
  }
}
